export const checkRules = {
  methods: {
    checkRules(rules, value) {
      let valid = true;
      rules.forEach((rule) => {
        if (rule(value) !== true) {
          valid = false;
        }
      });
      return valid;
    },
  },
};

/**
 * Exception handler.
 */
export const eHandler = {
  methods: {
    /**
    * Clear an exception.
    *
    * @param {Object} exception - created exception.
    * @param {String} type - type of exception to be cleared.
    *
    * @returns {Object} - empty object or exception.
    */
    eClear(exception, type) {
      if (exception.type === type) {
        return {};
      }
      return exception;
    },

    /**
    * Create an exception.
    *
    * @param {Object} e - exception.
    *
    * @returns {Object} - exception message and type
    */
    eCreate(e) {
      return { message: e.message, type: e.type };
    },

    /**
    * Reset form.
    *
    * @param {String} form - form to be reseted.
    */
    resetForm(form) {
      setTimeout(() => this.$refs[form].reset());
    },

    /**
    * Validate form.
    *
    * @param {String} form - form to be validated.
    */
    validateForm(form) {
      setTimeout(() => this.$refs[form].validate());
    },
  },
};

/**
 * Generates a srcset from an image.
 *
 * @param {String} image - image path.
 */
export const generateSrcSet = {
  methods: {
    generateSrcSet(image) {
      const ext = image.split('.').pop();
      const img = image.replace(`.${ext}`, '');

      return `
        ${img}-s.${ext} 480w,
        ${img}-m.${ext} 768w,
        ${img}-l.${ext} 1024w,
        ${img}-xl.${ext} 1280w,
        ${img}.${ext} 1920w
      `;
    },
  },
};

export const generateImageSources = {
  methods: {
    generateImageSources(image) {
      const ext = image.split('.').pop();
      const img = image.replace(`.${ext}`, '');

      return [
        {
          media: '(max-width: 30em)',
          srcset: `${img}-s.${ext}`,
        },
        {
          media: '(max-width: 48em)',
          srcset: `${img}-m.${ext}`,
        },
        {
          media: '(max-width: 64em)',
          srcset: `${img}-l.${ext}`,
        },
        {
          media: '(max-width: 80em)',
          srcset: `${img}-xl.${ext}`,
        },
        {
          media: '(max-width: 120em)',
          srcset: `${img}.${ext}`,
        },
      ];
    },
  },
};

export const goToError = {
  methods: {
    goToError({
      duration = 200,
      easing = 'easeInOutCubic',
      offset = 64,
      selector = '.error--text',
    } = {}) {
      this.$nextTick(() => {
        this.$vuetify.goTo(selector, {
          duration,
          easing,
          offset,
        });
      });
    },
  },
};
